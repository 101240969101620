import { PillButton } from "@allsynx/components";
import React, { useLayoutEffect, useRef, useState } from "react";


function ShowHideParagraph(props: { text: string, maxHeight: number }) {
	const contentRef = useRef<HTMLParagraphElement>(null);
	const [isLarger, setIsLarger] = useState(false);
	const [isExpanded, setIsExpanded] = useState(false);

	useLayoutEffect(() => {
		if (contentRef.current) {
			const height = contentRef.current.clientHeight;
			if (height > props.maxHeight) {
				setIsLarger(true);
			}
		}
	  }, []);

	const toggleShow = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
		e.preventDefault();
		setIsExpanded(!isExpanded);
	}
	
	return (
		<>
			<p ref={contentRef} 
				style={{ maxHeight: (!isLarger || isExpanded) ? 'none' : props.maxHeight, overflow: 'hidden', maskImage: (!isLarger || isExpanded) ? 'none' : 'linear-gradient(black, black, black, transparent)' }} 
				dangerouslySetInnerHTML={{ __html: props.text.replaceAll("\r", "<br />") }} />
			{isLarger && (<div><a href="#" onClick={toggleShow}>Show {isExpanded ? 'Less' : 'More'}</a></div>)}
			
		</>
	);
}

export default ShowHideParagraph;