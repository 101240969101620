import { Outlet } from "react-router-dom";
import { SideNavbar, MainContainer} from '@allsynx/components';
import { useEffect, useContext } from 'react';
import { NavigationContext } from "contexts/navigation-context";
import { localStorageUtil } from "utils/local-storage-util";
import { useParams, useOutletContext } from "react-router-dom";
import { getTokenClaim } from "utils/jwt-util";
import { GetUserTypeNumeric, UserTypes } from "enums/user-types";

function Settings() {
	const { sideBarNavItems,setFooterHasSideBar} = useContext(NavigationContext);
	const setWebDirForLogo = useOutletContext<(src: string, id?: number) => void>();

	const { webDir } = useParams();
	
	useEffect(() => {
		setFooterHasSideBar(true);
	}, []);

	useEffect(() => {
		const setWebDirAsync = async () => {
			var userTypeClaim = await getTokenClaim("UserType");
			if (webDir && userTypeClaim != GetUserTypeNumeric(UserTypes.SystemAdmin).toString()) {
				localStorageUtil.storeItem("webDir", webDir);
				setWebDirForLogo(webDir);
			} else {
				setWebDirForLogo("admin");
			}
		};
		setWebDirAsync();
	}, [webDir, setWebDirForLogo]);

	return (
		<>
			<SideNavbar navItems={sideBarNavItems} themeColor="blue"/> 
			<MainContainer>
				<Outlet />
			</MainContainer>
		</>
	);
}

export default Settings;